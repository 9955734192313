(function () {
	angular.module('Plania').controller('EquipmentController', ['$scope', 'Repository', '$stateParams', '$location', '$modal', 'TranslationService', '$localStorage', '$rootScope', '$timeout', 'Constants', controller]);
	function controller($scope, repository, $stateParams, $location, $modal, translationService, $localStorage, $rootScope, $timeout, constants) {
		$scope.update = $scope.navigation.current.name === 'equipment.edit';
		$scope.model = { Guid: $stateParams.guid };
		$scope.activateAutocomplete = false;

		//Check reloads
		$scope.reloadDocumentTable = false;
		$scope.reloadOperationalMessages = false;
		$scope.reloads = {
			deviations: false,
			controlLists: false,
			bimModels: false
		};

		$scope.useNonsReference = $localStorage.generalOptions.UseNonsReference;

		var availableHashLocations = ["#info", "#maintenance", "#image"];
		var updateLocation = function () {
			var hash = window.location.hash;
			if (hash && availableHashLocations.includes(hash)) {
				$('#equipmentTabs a[data-target="' + hash + '"]').tab('show');
				$scope.currentTab = hash.substring(1);
			}
			else {
				$('#equipmentTabs a[data-target="#info"]').tab('show');
				$scope.currentTab = 'info';
			}
		};

		$scope.$on('$locationChangeSuccess', function (event, newUrl, oldUrl) {
			updateLocation();
		});
		$scope.setTab = function (tab) {
			$location.hash(tab).replace();
		};

		if ($scope.isUpdate && $stateParams.tab) {
			$scope.setTab($stateParams.tab);
			updateLocation();
		} else {
			updateLocation();
		}

		var updateAccess = function () {
			if ($scope.isUpdate) {
				var checkOtherDO = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
				$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.Equipment, checkOtherDO);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.Equipment, checkOtherDO);
			} else {
				$scope.restrictEdit = !repository.authService.hasCreateAccess(repository.commonService.prefix.Equipment);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.Equipment);
			}
		};
		updateAccess();

		var columns = [
			'Building.Id', 'Building.Description', 'Building.Guid', 'Building.GuidEstate',
			'Building.Estate.Id', 'Building.Estate.Description', 'Building.Estate.Guid',
			'EquipmentGroup.Id', 'EquipmentGroup.Description',
			'CostCenter.Id', 'CostCenter.Description',
			'EquipmentCategory.Description', 'EquipmentCategory.Icon', 'EquipmentCategory.Color',
			'Supplier.Description',
			'Area.Id', 'Area.Description', 'Area.Guid',
			'Account.Description',
			'OperationsManager.Guid', 'OperationsManager.FirstName', 'OperationsManager.LastName',
			'NonsReference.RefString'
		];

		if ($scope.update) {
			repository.getMainModel(repository.apiData.equipment.url, $scope.model.Guid, JSON.stringify(columns)).then(function (response) {
				$scope.model = response.Data;

				updateAccess();
				$scope.reloads.deviations = true;
				$scope.reloads.controlLists = true;
				$scope.reloads.bimModels = true;
				$scope.reloadDocumentTable = true;
				$scope.reloadOperationalMessages = true;

				setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
			}, function (error) {
				repository.showError(error);
			});
		} else {
			var getCategoryPrefill = function (guid) {
				var guidCategory = guid || $scope.model.GuidEquipmentCategory;
				if (!guidCategory) return;
				repository.getSingle(repository.apiData.equipmentCategory.url, guidCategory).then(function (result) {
					$scope.model.DynamicProperty = result.Data.DynamicProperty;
				}, function (error) {
					repository.showError(error);
				});
			};

			var selectedBuilding = repository.commonService.getFilterData().selectedBuilding;
			if (selectedBuilding.Guid) {
				$scope.model.GuidBuilding = selectedBuilding.Guid;
				$scope.model.Building = { Caption: selectedBuilding.Name };
			}

			var webMenuParams = repository.getMenuParameters($scope.navigation.params.menuGuid);
			if (webMenuParams && webMenuParams.Prefill) {
				webMenuParams.Prefill.forEach(function (prefill) {
					if (typeof (prefill.Value) === 'object') {
						switch (prefill.Property) {
							case 'Building':
								$scope.model.Building = { Caption: prefill.Value.Label };
								$scope.model.GuidBuilding = prefill.Value.Guid;
								break;
							case 'EquipmentCategory':
								$scope.model.EquipmentCategory = { Guid: prefill.Value.Guid, Id: prefill.Value.Label, Caption: prefill.Value.Label };
								$scope.model.GuidEquipmentCategory = prefill.Value.Guid;
								break;
						}
					} else {
						$scope.model[prefill.Property] = prefill.Value;
					}
				});
			}

			if ($stateParams.guidEntityToCopy) {
				repository.getSingle(repository.apiData.equipment.url, $stateParams.guidEntityToCopy).then(function (result) {
					delete result.Data.Guid;
					delete result.Data.GuidGisEntity;
					delete result.Data.GuidNonsReference;

					$scope.model = result.Data;
					$scope.model.Id += '-N';

					$scope.activateAutocomplete = false;
					$timeout(function () { $scope.activateAutocomplete = true; }, 100);
				});
			}

			if ($stateParams.showPersistantData) {
				var persistentData = repository.persistedData.getPersistedData('equipment.create');

				if (persistentData) {
					$scope.model.GuidBuilding = persistentData.GuidBuilding;
					$scope.model.Building = typeof (persistentData.Building) === 'string' ? { Caption: persistentData.Building } : persistentData.Building;

					// Just in case the guidArea and area with lowercase is used elsewhere
					$scope.model.GuidArea = persistentData.guidArea ? persistentData.guidArea : persistentData.GuidArea;
					var persistentArea = persistentData.area ? persistentData.area : persistentData.Area;
					$scope.model.Area = typeof (persistentArea) === 'string' ? { Caption: persistentArea } : persistentArea;

					$scope.model.GuidEquipmentGroup = persistentData.GuidEquipmentGroup;
					$scope.model.EquipmentGroup = typeof (persistentData.EquipmentGroup) === 'string' ? { Caption: persistentData.EquipmentGroup } : persistentData.EquipmentGroup;

					if (persistentData.IsTemplate) {
						$scope.model.IsTemplate = true;
					} else if (typeof (persistentData.Building) === "object" && persistentData.Building.IsTemplate) {
						$scope.model.IsTemplate = true;
					} else if (typeof (persistentData.EquipmentGroup) === "object" && persistentData.EquipmentGroup.IsTemplate) {
						$scope.model.IsTemplate = true;
					}
				}
			}
			if (!$stateParams.guidEntityToCopy)
				getCategoryPrefill();

			$scope.activateAutocomplete = true;
			$scope.model.IsMaintainable = true;
		}

		$scope.sendApprovalRequest = function () {
			swal({
				title: translationService.translate('web-swal-equipment-approval-title',
					'Vil du sende anlegget til godkjenning?'),
				text: translationService.translate('web-swal-equipment-approval-message',
					'Anlegget vil bli markert "avventer godkjenning"'),
				type: "info",
				showCancelButton: true,
				confirmButtonColor: "#337ab7",
				confirmButtonText: translationService.translate('web-swal-equipment-button-confirm-approval',
					'Ja, send til godkjenning!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				var success = function (result) {
					repository.growl(translationService.translate('web-equipment-approval-sent-confirmation', 'Anlegg har blitt sendt til godkjenning!'), 'success');
					$scope.model.DataAcquisitionStatus = result.DataAcquisitionStatus;
				};
				var updateError = function (result) {
					repository.showError(result);
				};

				repository.runAction(repository.apiData.equipment.endpoint.setPendingApproval + $scope.model.Guid).then(success, updateError);
			});
		};

		$scope.updateEquipment = function (destination) {
			var success = function (result) {
				repository.growl($scope.update ?
					translationService.translate('update-equipment-success', 'Anlegg har blitt oppdatert.') :
					translationService.translate('create-equipment-success', 'Anlegg har blitt opprettet.'), 'success');

				var equipmentGuid = $scope.update ? $scope.model.Guid : result.Guid;
				repository.commonService.setLastRegisterGuid(destination, equipmentGuid);
				if (destination === 'equipment.create') {
					repository.persistedData.setPersistedData(destination, { guidBuilding: $scope.model.GuidBuilding, building: $scope.model.Building });
					$scope.navigation.go(destination, { showPersistantData: true, menuGuid: $scope.navigation.params.menuGuid }, { reload: true });
				} else {
					repository.persistedData.clearPersistedData();
					$scope.goBack(destination, { guid: equipmentGuid, menuGuid: $scope.navigation.params.menuGuid });
				}
			};

			var error = function (error) {
				repository.showError(error);
			};

			if ($scope.update) {
				repository.updateSingle(repository.apiData.equipment.url, $scope.model).then(success).catch(error);
				if ($scope.nonsModel)
					repository.updateSingle(repository.apiData.nonsReference.url, $scope.nonsModel).then(success).catch(error);
			} else
				repository.createSingle(repository.apiData.equipment.url, $scope.model).then(success).catch(error);
		};

		$scope.cancel = function () {
			repository.commonService.setLastRegisterGuid('equipment.list', $scope.model.Guid);
			$scope.goBack('equipment.list', { menuGuid: $scope.navigation.params.menuGuid });
		};

		$scope.removeEquipment = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-equipment-message', 'Anlegget vil bli permanent fjernet!'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-equipment-button-confirm', 'Ja, slett anlegget!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				repository.deleteSingle(repository.apiData.equipment.url, $scope.model.Guid).then(function (result) {
					swal(translationService.translate('web-swal-equipment-success', 'Anlegget ble fjernet!'), result, "success");
					repository.growl(result, 'success');
					$scope.goBack('equipment.list', { menuGuid: $scope.navigation.params.menuGuid });
				}, function (error) {
					swal({
						title: "Kunne ikke slette anlegget",
						text: error.Message + "<br/><br/> Vil du deaktivere anlegget?",
						type: "error",
						html: true,
						showCancelButton: true,
						confirmButtonText: "Deaktiver",
						cancelButtonText: "Avbryt",
						closeOnConfirm: true,
						closeOnCancel: true
					},
						function (isConfirm) {
							window.onkeydown = null;
							window.onfocus = null;
							if (isConfirm) {
								$scope.model.IsDeactivated = true;
								$scope.updateEquipment('equipment.list');
							}
						}
					);
				});
			});
		};

		$scope.$on($scope.events.newSelection, function () {
			updateAccess();
		});

		if ($scope.update && $rootScope.hasModule('Elhub')) {
			var elhubIdField = $localStorage.generalOptions.EquipmentFieldForMeteringIdentifier;

			var matchElhubMeteringPointFormat = function (id) {
				if (!id || id.length !== 18) return false;
				return /^\d+$/.test(id);
			};

			var checkElhubFunctions = function () {
				var status = $scope.model.ElhubStatus;
				$scope.showElhubStatus = status && status !== constants.elhubStatus.undefined && status !== constants.elhubStatus.noExport;
				$scope.showElhubOption = $scope.showElhubStatus || ($scope.model && matchElhubMeteringPointFormat($scope.model[elhubIdField]));
			};

			checkElhubFunctions();
			$scope.$watch('model.Id', function () {
				checkElhubFunctions();
			});

			$scope.showElhubModal = function () {
				$modal.open({
					templateUrl: 'app/equipment/views/equipmentElhubStatusModal.html',
					controller: 'EquipmentElhubStatusModalController',
					resolve: {
						params: function () { return { elhubStatus: $scope.model.ElhubStatus }; }
					}
				}).result.then(function (newElhubStatus) {
					$scope.model.elhubStatus = newElhubStatus;
					$scope.updateEquipment('equipment.edit');
				});
			};
		}

		$scope.openTFMModal = function () {
			$modal.open({
				controller: 'NonsReferenceModalController',
				templateUrl: 'app/common/modal/views/nonsReferenceModal.html',
				size: 'xxl',
				resolve: {
					params: function () {
						return {
							entityModel: $scope.model
						};
					}
				}
			}).result.then(function (nonsReference) {
				$scope.model.NonsReference = nonsReference;
				$scope.model.GuidNonsReference = nonsReference.Guid;
			});
		};

		$scope.changeId = function () {
			$modal.open({
				templateUrl: 'app/common/modal/views/changeIdModal.html',
				controller: 'ChangeIdModalController',
				size: 'md',
				resolve: {
					params: function () {
						return {
							id: $scope.model.Id
						};
					}
				}
			}).result.then(function (id) {
				if (id) {
					repository.patch(repository.apiData.equipment.url, $scope.model.Guid, { Id: id }).then(function (result) {
						repository.growl(translationService.translate('web-equipment-changeId-success', 'Id ble oppdatert'), 'success');
						$scope.model.Id = id;
					}, function (error) {
						repository.showError(error);
					});
				}
			});
		};




	}
})();
