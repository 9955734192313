(function () {
	var app = angular.module('Plania');
	app.directive('equipmentInfoDirective', function () {
		return {
			restrict: 'E',
			scope: {
				model: '=ngModel',
				restrictEdit: '=restrictEdit',
				reloads: '=reloads',
				update: '=update',
				reloadOperationalMessages: '=reloadOperationalMessages',
				reloadDocumentTable: '=reloadDocumentTable',
				activateAutocomplete: '=activateAutocomplete',
				currentTab: '=currentTab',
				formReference: '=', // Is automatically passed to the current scope, in case outer parent works with forms.
			},
			controller: ['$scope', 'ngTableParams', 'Repository', 'TranslationService', '$modal', '$rootScope', 'ListService', 'authService', 'RegisteredFieldService', '$localStorage', '$timeout', controller],
			templateUrl: 'app/equipment/views/equipmentInfo.html',
		};
	});

	function controller($scope, ngTableParams, repository, translationService, $modal, $rootScope, listService, authService, registeredFieldService, $localStorage, $timeout) {
		$scope.hasReadAccess = $rootScope.hasReadAccess;
		$scope.hasDeleteAccess = $rootScope.hasDeleteAccess;
		$scope.hasCreateAccess = $rootScope.hasCreateAccess;
		$scope.hasEditAccess = $rootScope.hasEditAccess;
		$scope.hasModule = $rootScope.hasModule;
		$scope.userInfo = $rootScope.userInfo;
		$scope.customization = $rootScope.customization;
		$scope.generalOptions = $localStorage.generalOptions;
		$scope.imageApiUrl = $rootScope.imageApiUrl;

		$scope.viewMode = !!$scope.update;
		$scope.isDocumentsCollapsed = true;
		$scope.isDynamicPropertiesCollapsed = false;

		$scope.showNonsReference = $scope.generalOptions.UseNonsReference && $scope.update;
		$scope.entityCommentSearch = { searchString: '', focus: false };
		$scope.canSeeEntityComment = false;

		$scope.toggleViewMode = function () {
			$scope.viewMode = !$scope.viewMode;
		};

		var updateEntityCommentVisibility = function () {
			$scope.canSeeEntityComment = $scope.hasModule("DataAcquisition") &&
				$scope.hasModule('EntityComment') &&
				$scope.hasReadAccess('EntityComment') &&
				$scope.update;
			return $scope.canSeeEntityComment;
		};
		updateEntityCommentVisibility();

		$scope.hasPageLoaded = $scope.currentTab === 'info';
		if (!$scope.hasPageLoaded) {
			var pageListener = $scope.$watch('currentTab', function (newVal, oldVal) {
				if (newVal === oldVal) return;
				$timeout(function () {
					if (newVal === 'info' && !$scope.hasPageLoaded) {
						$scope.hasPageLoaded = true;
						pageListener();
						updateEntityCommentVisibility();
					}
				}, 250);
			});
		}

		$scope.onBuildingSelect = function (building) {
			if (!building) {
				$scope.model.GuidBuilding = "";
				return;
			}
			if ($scope.model.GuidBuilding !== building.Guid) {
				$scope.model.GuidEquipmentGroup = "";
				delete $scope.model.EquipmentGroup;
				$scope.model.GuidArea = "";
				delete $scope.model.Area;
			}
			$scope.model.GuidBuilding = building.Guid;
		};

		$scope.onEquipmentCategorySelect = function (category) {
			if (!category) {
				$scope.newDynamicProperty = null;
				$scope.model.GuidEquipmentCategory = "";
				return;
			}
			if (category.DynamicProperty && category.DynamicProperty.Groups.length > 0) {
				$scope.newDynamicProperty = category.DynamicProperty;
			} else {
				$scope.newDynamicProperty = null;
			}
			$scope.model.GuidEquipmentCategory = category.Guid;
		};

		$scope.onEquipmentSelect = function (equipment) {
			if (!equipment) {
				$scope.model.GuidEquipmentGroup = "";
				return;
			}
			$scope.model.GuidEquipmentGroup = equipment.Guid;

			if (!$scope.model.GuidBuilding || $scope.model.GuidBuilding === "") {
				$scope.model.GuidBuilding = equipment.GuidBuilding;
				$scope.model.Building = equipment.Building;
			}

			if (equipment.GuidArea) {
				$scope.model.GuidArea = equipment.GuidArea;
				$scope.model.Area = equipment.Area;
			}
		};

		$scope.autoCompleteFilter = function (filterName) {
			switch (filterName) {
				case "equipment":
					var filter = {
						GuidBuilding: $scope.model.GuidBuilding,
						RestrictHierarchy: true,
						PropertyFilter: [{ Property: 'IsEquipmentGroup', Operator: '=', Value: '1' }]
					};

					if ($scope.update) {
						filter.PropertyFilter.push({ Property: 'GUID', Operator: '<>', Value: $scope.model.Guid });
					}

					return filter;
				case "area":
					if ($localStorage.generalOptions.EqMustBeLocatedInOwnBld)
						return { PropertyFilter: [{ Property: 'GuidBuilding', 'operator': '=', Value: $scope.model.GuidBuilding }] };
					return {};
				case "person":
					return { PropertyFilter: [{ Property: 'IsOperationsManager', Value: true, Operator: '=' }] };
			}
			return null;
		};

		registeredFieldService.getRegisteredField(repository.commonService.prefix.Equipment).then(function (registeredFields) {
			var isExternalUser = $localStorage.userData.isExternalUser;
			var locationRule = _.filter(registeredFields, { DomainFieldName: 'Location' })[0];
			var showLocation = locationRule ? (locationRule.Hidden ? false : isExternalUser ? !locationRule.ExternallyHidden : true) : false;
			var areaRule = _.filter(registeredFields, { DomainFieldName: 'GuidArea' })[0];
			var showArea = areaRule ? (areaRule.Hidden ? false : isExternalUser ? !areaRule.ExternallyHidden : true) : false;
			$scope.hidePlacementSection = !showLocation && !showArea;
		});

		if ($scope.update) {
			$scope.getPropertyValue = function (item, column) {
				return listService.GetPropertyValue(item, column);
			};

			var getMenuLink = function (url, icon, text) {
				return {
					html: '<a class="dropdown-item" tabindex= "-1" href="' + url + '" ><i class="fas ' + icon + ' fa-hc-fw fa-hc-lg"></i> ' + text + '</a>',
					isHref: true
				};
			};

			$scope.componentInfo = new function () {
				var me = this;

				this.items = [];
				this.search = {};

				this.columns = [
					{ Position: 1, Title: translationService.translate('web-component-id'), Property: 'Component.Id', PropertyType: 'string' },
					{ Position: 2, Title: translationService.translate('web-component-description'), Property: 'Component.Description', PropertyType: 'string' },
					{ Position: 3, Title: translationService.translate('web-componentCategory-description'), Property: 'Component.ComponentCategory.Description', PropertyType: 'string' },
					{ Position: 5, Title: translationService.translate('web-componentxequipment-quantity', 'Antall'), Property: 'Quantity', PropertyType: 'string' },
				];

				this.initTable = function () {
					if (me.table) return;
					me.table = new ngTableParams(
						{
							page: 1,
							count: 5,
							sorting: { 'Component.Id': 'asc' },
							filter: { PropertyFilter: [{ Property: "GuidEquipment", Operator: "=", Value: $scope.model.Guid }] }
						},
						{
							total: 0,
							filterDelay: 10,
							counts: [5, 10, 20],
							paginationMaxBlocks: 6,
							getData: function ($defer, params) {
								var columns = [];
								me.columns.forEach(function (col) {
									columns.push(col.Property);
								});

								columns.push('Note');
								columns.push('Component.Guid');
								columns.push('Component.GuidImage');

								repository.GetPaginated(repository.apiData.componentEquipment.url, params.page() - 1, params.count(), params.sorting(), params.filter(), null, JSON.stringify(columns)).then(function (result) {
									me.items = result.List;
									me.table.settings().total = result.TotalCount;
									me.table.settings().filterDelay = 500;
									$defer.resolve(result.List);
								}, function (error) {
									repository.showError(error);
								});
							}
						});
				};

				this.add = function () {
					$modal.open({
						templateUrl: 'app/common/views/multipleSelectModal.html',
						controller: 'MultipleSelectModalController',
						resolve: {
							modalParams: function () {
								return {
									title: 'Legg til komponent',
									url: repository.apiData.component.url,
									columns: [
										{ Title: translationService.translate('web-component-id'), Property: 'Id', PropertyType: 'string' },
										{ Title: translationService.translate('web-component-description'), Property: 'Description', PropertyType: 'string' },
									],
									filter: {
										FilterModel: {
											Condition: "and",
											Rules: [{
												Property: "ComponentXEquipments.GuidEquipment",
												Operator: "<>",
												Value: $scope.model.Guid,
												CollectionOperator: "all"
											}]
										}
									},
									useObjects: false
								};
							}
						}
					}).result.then(function (guids) {
						var model = [];

						guids.forEach(function (guid) {
							model.push({ GuidEquipment: $scope.model.Guid, GuidComponent: guid });
						});

						repository.createMultiple(repository.apiData.componentEquipment.url, model).then(function (result) {
							me.table.reload();
						}, function () {
							me.table.reload();
						});
					});
				};

				this.update = function (item) {
					if (!me.canEdit(item)) return;

					$modal.open({
						templateUrl: 'app/equipment/views/editComponentEquipment.html',
						controller: 'EquipmentComponentController',
						resolve: {
							params: function () { return { component: item, AddComponents: false }; }
						}
					}).result.then(function () {
						me.table.reload();
					});
				};

				$scope.openTFMModal = function () {
					$modal.open({
						controller: 'NonsReferenceModalController',
						templateUrl: 'app/common/modal/views/nonsReferenceModal.html',
						size: 'xxl',
						resolve: {
							params: function () {
								return {
									entityModel: $scope.model
								};
							}
						}
					}).result.then(function (nonsReference) {
						$scope.model.NonsReference = nonsReference;
						$scope.model.GuidNonsReference = nonsReference.Guid;
					});
				};

				this.remove = function (item) {
					if (!me.canDelete(item)) return;
					swal({
						title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
						text: translationService.translate('web-swal-equipment-component-message', 'Komponenten vil bli permanent fjernet!'),
						type: "warning",
						showCancelButton: true,
						confirmButtonColor: "#f44336",
						confirmButtonText: translationService.translate('web-swal-equipment-component-button-confirm', 'Ja, fjern komponenten!'),
						cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
						closeOnConfirm: false,
						showLoaderOnConfirm: true
					}, function () {
						window.onkeydown = null;
						window.onfocus = null;
						repository.deleteSingle(repository.apiData.componentEquipment.url, item.Guid).then(function (result) {
							swal(translationService.translate('web-swal-equipment-component-success', 'Komponenten ble fjernet!'), result, "success");
							me.table.reload();
						});
					});
				};

				this.contextMenuOptions = function (item) {
					var options = [];

					if ($scope.hasReadAccess('Component')) {
						options.push(getMenuLink($rootScope.navigation.href('component.edit', { guid: item.GuidComponent }), 'fa-arrow-right fa-hc-fw fa-hc-lg', translationService.translate('web-equipment-componentXEquipmentContextMenu-viewComponent', 'Gå til komponent')));
					}

					if (me.canEdit(item)) {
						options.push({
							html: '<a class="dropdown-item" tabindex= "-1" href= "#"> ' + translationService.translate('web-button-edit', 'Rediger') + '</a >',
							click: function () {
								me.update(item);
							}
						});
					}

					if (me.canDelete(item)) {
						options.push({
							html: '<a class="dropdown-item" tabindex= "-1" href= "#"> ' + translationService.translate('web-button-delete', 'Slett') + '</a >',
							click: function () {
								me.remove(item);
							}
						});
					}

					return options;
				};

				this.canEdit = function (item) {
					return item &&
						$scope.hasEditAccess('ComponentXEquipment');
				};

				this.canDelete = function (item) {
					return item &&
						$scope.hasDeleteAccess('ComponentXEquipment');
				};

				me.initTable();
			}();

			$scope.conditionInfo = new function () {
				var me = this;

				this.columns = [
					{ Position: 1, Title: translationService.translate('web-condition-conditionType'), Property: 'ConditionType.Description', PropertyType: 'string' },
					{ Position: 2, Title: translationService.translate('web-condition-Workorder-Id', "Arbeidsordre Id"), Property: 'WorkOrder.Id', PropertyType: 'string' },
					{ Position: 3, Title: translationService.translate('web-condition-Workorder-Description', "Arbeidsordre"), Property: 'WorkOrder.Description', PropertyType: 'string' },
					{ Position: 4, Title: translationService.translate('web-condition-CreatedBy', "Opprettet av"), Property: 'UserCreatedBy.RealName', PropertyType: 'string' },
					{ Position: 5, Title: translationService.translate('web-condition-CreationDate', "Opprettet"), Property: 'CreationDate', PropertyType: 'date' },
				];

				this.initTable = function () {
					if (me.table) return;
					me.table = new ngTableParams(
						{
							page: 1,
							count: 10,
							sorting: { CreationDate: 'desc' },
							filter: { PropertyFilter: [{ Property: "GuidEquipment", Operator: "=", value: $scope.model.Guid }, { Property: "GuidConditionType", Operator: "<>", value: null }] }
						},
						{
							total: 0,
							counts: [10, 20, 50],
							filterDelay: 10,
							paginationMaxBlocks: 6,
							getData: function ($defer, params) {
								var columns = [];
								me.columns.forEach(function (col) {
									columns.push(col.Property);
								});

								columns.push('WorkOrder.Guid');

								repository.GetPaginated(repository.apiData.condition.url, params.page() - 1, params.count(), params.sorting(), params.filter(), "", JSON.stringify(columns)).then(function (result) {
									me.table.settings().total = result.TotalCount;
									me.table.settings().filterDelay = 500;
									$defer.resolve(result.List);
								}, function (error) {
									repository.showError(error);
								});
							}
						});
				};

				me.initTable();
			}();

			$scope.equipmentHourInfo = new function () {
				var me = this;

				this.search = {};

				this.columns = [
					{ Position: 1, Title: translationService.translate('web-EquipmentOperatingHours-Value', 'Verdi'), Property: 'Value', PropertyType: 'string' },
					{ Position: 2, Title: translationService.translate('web-EquipmentOperatingHours-EquipmentOperatingHourType-id', 'Type'), Property: 'EquipmentOperatingHourType.Id', PropertyType: 'string' },
					{ Position: 3, Title: translationService.translate('web-EquipmentOperatingHours-ReadDate', 'Registrert dato'), Property: 'ReadDate', PropertyType: 'date' },
					{ Position: 4, Title: translationService.translate('web-EquipmentOperatingHours-CreationDate', 'Opprettet'), Property: 'CreationDate', PropertyType: 'date' },
					{ Position: 5, Title: translationService.translate('web-EquipmentOperatingHours-CreatedBy', 'Opprettet av'), Property: 'UserCreatedBy.RealName', PropertyType: 'string' },
				];

				registeredFieldService.getFieldRule(repository.commonService.prefix.equipmentOperatingHours, 'GuidEquipmentOperatingHourType').then(function (result) {
					if (result.Hidden || (authService.getUserData().isExternalUser && result.ExternallyHidden)) {
						me.columns = [
							{ Position: 1, Title: translationService.translate('web-EquipmentOperatingHours-Value', 'Verdi'), Property: 'Value', PropertyType: 'string' },
							{ Position: 2, Title: translationService.translate('web-EquipmentOperatingHours-ReadDate', 'Registrert dato'), Property: 'ReadDate', PropertyType: 'date' },
							{ Position: 3, Title: translationService.translate('web-EquipmentOperatingHours-CreationDate', 'Opprettet'), Property: 'CreationDate', PropertyType: 'date' },
							{ Position: 4, Title: translationService.translate('web-EquipmentOperatingHours-CreatedBy', 'Opprettet av'), Property: 'UserCreatedBy.RealName', PropertyType: 'string' },
						];
					}
				});

				this.listSetup = {
					VisibleColumns: me.columns,
					Columns: me.columns,
					Id: $scope.model ? $scope.model.Caption : ""
				};

				$scope.$watch('model.Caption', function (newVal) {
					me.listSetup.Id = newVal;
				});

				this.dropdownFilters = [{ Guid: undefined, Id: 'Alle' }];
				this.selectedDropdownFilter = me.dropdownFilters[0];

				this.setDropdownFilter = function (type) {
					me.selectedDropdownFilter = type;
					me.table.reload();
				};

				var GetOperatingHourTypeFilters = function () {
					var filter = {
						PropertyFilter: [
							{ Property: 'EquipmentOperatingHours.GuidEquipment', Operator: '=', Value: $scope.model.Guid }
						]
					};
					repository.GetPaginated(repository.apiData.equipmentOperatingHourType.url, 0, 100, { id: 'asc' }, filter, "", JSON.stringify(['Id'])).then(function (result) {
						me.dropdownFilters = result.List;
						me.dropdownFilters.unshift({ Guid: null, Id: 'Uten type' });
						me.dropdownFilters.unshift({ Guid: undefined, Id: 'Alle' });
					});
				};

				GetOperatingHourTypeFilters();

				this.initTable = function () {
					if (me.table) return;
					me.table = new ngTableParams(
						{
							page: 1,
							count: 10,
							sorting: { ReadDate: 'desc' },
							filter: {}
						},
						{
							total: 0,
							counts: [10, 20, 50],
							filterDelay: 10,
							paginationMaxBlocks: 6,
							getData: function ($defer, params) {
								var columns = [];
								me.columns.forEach(function (col) {
									columns.push(col.Property);
								});

								var filter = params.filter();
								filter.PropertyFilter = [{ Property: "GuidEquipment", Operator: "=", value: $scope.model.Guid }];
								if (me.selectedDropdownFilter.Guid !== undefined) {
									filter.PropertyFilter.push({
										Property: "GuidEquipmentOperatingHourType",
										Operator: "=",
										value: me.selectedDropdownFilter.Guid
									});
								}

								repository.GetPaginated(repository.apiData.equipmentOperatingHours.url,
									params.page() - 1,
									params.count(),
									params.sorting(),
									params.filter(),
									"",
									JSON.stringify(columns))
									.then(function (result) {
										me.table.settings().total = result.TotalCount;
										me.table.settings().filterDelay = 500;
										$defer.resolve(result.List);
									}, function (error) {
										repository.showError(error);
									});
							}
						});
				};

				this.add = function () {
					$modal.open({
						templateUrl: 'app/equipment/views/equipmentOperatingHoursModal.html',
						controller: 'EquipmentOperatingHoursController',
						size: 'sm',
						resolve: {
							params: function () { return { GuidEquipment: $scope.model.Guid, ReadDate: new Date() }; }
						}
					}).result.then(function () {
						me.table.reload();
						GetOperatingHourTypeFilters();
					});
				};

				this.update = function (item) {
					if (!me.canEdit(item)) return;
					$modal.open({
						templateUrl: 'app/equipment/views/equipmentOperatingHoursModal.html',
						controller: 'EquipmentOperatingHoursController',
						size: 'sm',
						resolve: {
							params: function () { return item; }
						}
					}).result.then(function () {
						me.table.reload();
						GetOperatingHourTypeFilters();
					});
				};

				this.remove = function (item) {
					if (!me.canDelete(item)) return;
					swal({
						title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
						text: translationService.translate('web-swal-equipment-equipmentOperatingHour-message',
							'Driftstimene vil bli permanent fjernet!'),
						type: "warning",
						showCancelButton: true,
						confirmButtonColor: "#f44336",
						confirmButtonText: translationService.translate('web-swal-equipment-equipmentOperatingHour-button-confirm',
							'Ja, fjern driftstimene!'),
						cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
						closeOnConfirm: false,
						showLoaderOnConfirm: true
					}, function () {
						window.onkeydown = null;
						window.onfocus = null;
						repository.deleteSingle(repository.apiData.equipmentOperatingHours.url, item.Guid).then(function (result) {
							swal(translationService.translate('web-swal-equipment-equipmentOperatingHour-success',
								'Driftstimene ble fjernet!'),
								result,
								"success");
							me.table.reload();
							GetOperatingHourTypeFilters();
						});
					});
				};

				this.contextMenuOptions = function (item) {
					var options = [];

					if (me.canEdit(item)) {
						options.push({
							html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i class="fas  fa-pen-to-square fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-button-edit', 'Rediger') + '</a >',
							click: function () {
								me.update(item);
							}
						});
					}

					if (me.canDelete(item)) {
						options.push({
							html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i class="fas fa-trash fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-button-delete', 'Slett') + '</a >',
							click: function ($itemScope, $event, modelValue, text, $li) {
								me.remove(item);
							}
						});
					}

					return options;
				};

				this.canEdit = function (item) {
					return item &&
						$scope.hasEditAccess('EquipmentOperatingHours');
				};

				this.canDelete = function (item) {
					return item &&
						$scope.hasDeleteAccess('EquipmentOperatingHours');
				};

				me.initTable();
			}();

			$scope.$on($rootScope.events.newSelection, function () {
				updateEntityCommentVisibility();
			});

			$scope.$watchCollection('model.Images', function () {
				if ($scope.model && $scope.model.Images && Array.isArray($scope.model.Images)) {
					var defaultImage = $scope.model.Images.find(function (img) {
						return img.IsDefault;
					});
					if (defaultImage)
						$scope.model.GuidDefaultImage = defaultImage.Guid;
					else
						delete $scope.model.GuidDefaultImage;
				}
			});
		}
	}
})();
