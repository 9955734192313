(function () {
    angular.module('Plania').controller('ConsequenceController', ['$scope', 'Repository', '$stateParams', 'TranslationService', controller]);

    function controller($scope, repository, $stateParams, translationService) {
		$scope.isUpdate = $scope.navigation.current.name === 'consequence.edit';
		$scope.model = {
			Guid: $stateParams.guid,
			LikertScale: 0
		};

		var updateAccess = function () {
            if ($scope.isUpdate) {
                var checkOtherDO = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
                $scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.Consequence, checkOtherDO);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.Consequence, checkOtherDO);
            } else {
				$scope.restrictEdit = !repository.authService.hasCreateAccess(repository.commonService.prefix.Consequence);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.Consequence);
            }
        };

        if ($scope.isUpdate) {
			repository.getSingle(repository.apiData.consequence.url, $stateParams.guid).then(
                function(response) {
					$scope.model = response.Data;
                    updateAccess();
                    $scope.reload = true;
                },function(error) {
                    repository.growl(error, 'danger');
                }
            );
		}

        $scope.update = function (destination) {
            var success = function (result) {
				repository.growl($scope.isUpdate ? translationService.translate('update-consequence-success', 'Konsekvensgrad ble oppdatert.') : translationService.translate('create-conditionType-success', 'Konsekvensgraden ble opprettet.'), 'success');
                var guid = $scope.isUpdate ? $scope.model.Guid : result.Guid;
                repository.commonService.setLastRegisterGuid(destination, guid);
                $scope.goBack(destination, { guid: guid, menuGuid: $scope.navigation.params.menuGuid });
            };

            var error = function (error) {
                if (typeof (error) === "string") {
                    repository.growl(error, 'danger');
                } else {
                    repository.growl(error.Data.Message, 'danger');
                }
            };

            if ($scope.isUpdate)
				repository.updateSingle(repository.apiData.consequence.url, $scope.model).then(success).catch(error);
            else
				repository.createSingle(repository.apiData.consequence.url, $scope.model).then(success).catch(error);
        };

        $scope.remove = function () {
            swal({
                title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-consequence-message', "Konsekvensgraden vil bli permanent fjernet!"),
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-consequence-button-confirm', 'Ja, fjern konsekvensgraden!'),
                cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
            }, function () {
                window.onkeydown = null;
                window.onfocus = null;

				repository.deleteSingle(repository.apiData.consequence.url, $scope.model.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-consequence-success', 'Konsekvensgraden ble fjernet!'), result, "success");
						$scope.goBack('consequence.list', { menuGuid: $scope.navigation.params.menuGuid });
					});
            });
        };

        $scope.$on($scope.events.newSelection, function () {
            updateAccess();
        });
    }
})();
