(function () {
	angular.module('Plania').controller('SimpleGenericFormController', ['$scope', 'Repository', '$stateParams', 'TranslationService','$rootScope', controller]);

	function controller($scope, repository, $stateParams, translationService, $rootScope) {
		$scope.model = {};
		var routeEntity = '';

		if ($scope.navigation.current.name.indexOf('.edit') > -1) {
			routeEntity = $scope.navigation.current.name.replace('.edit', '');
		} else {
			routeEntity = $scope.navigation.current.name.replace('.create', '');
		}


		$scope.deadlineUnits = [
          { text: 'Dager', value: 0 },
          { text: 'Uker', value: 1 },
          { text: 'Måneder', value: 2 },
          { text: 'År', value: 3 },
          { text: 'Timer', value: 7 },
          { text: 'Ganger', value: 4 }
		];

		var prefix = routeEntity.charAt(0).toUpperCase() + routeEntity.slice(1);
		$scope.prefix = repository.commonService.prefix[prefix];
		$scope.isUpdate = $scope.navigation.current.name === routeEntity + '.edit';

		$scope.titleSystemName = $scope.isUpdate ? 'web-' + routeEntity + '-title' : 'web-' + routeEntity + '-title-create';
		$scope.titleDefaultName = $scope.isUpdate ? translationService.translate('web-' + routeEntity, routeEntity) : 'Opprett ' + translationService.translate('web-' + routeEntity, routeEntity);

		$scope.propertyLabels = {
			Id: {
				systemName: 'web-' + routeEntity + '-id',
				defaultName: 'Id'
			}
		};

		var updateAccess = function () {
			if ($scope.isUpdate) {
				var checkOtherDO = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
				$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix[prefix], checkOtherDO);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix[prefix], checkOtherDO);
			} else {
				$scope.restrictEdit = !repository.authService.hasCreateAccess(repository.commonService.prefix[prefix]);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix[prefix]);
			}
		};

		updateAccess();

		if ($scope.isUpdate) {
			repository.getSingle(repository.apiData[routeEntity].url, $stateParams.guid).then(
                function (response) {
                	$scope.model = response.Data;
                	updateAccess();
                }, function (error) {
                	repository.growl(error, 'danger');
                }
            );
		}

		$scope.update = function (destination) {
			var success = function (result) {
				repository.growl($scope.isUpdate ? translationService.translate('web-entity-update-success', 'Objektet har blitt oppdatert!') : translationService.translate('web-entity-create-success', 'Objektet har blitt opprettet!'), 'success');
				var guid = $scope.isUpdate ? $scope.model.Guid : result.Guid;
				repository.commonService.setLastRegisterGuid(routeEntity + '.' + destination, guid);
				$rootScope.goBack(routeEntity + '.' + destination, { guid: guid, menuGuid: $scope.navigation.params.menuGuid });
			};

			var error = function (error) {
				if (typeof (error) === "string") {
					repository.growl(error, 'danger');
				} else {
					repository.growl(error.Data.Message, 'danger');
				}
			};

			if ($scope.isUpdate) {
				repository.updateSingle(repository.apiData[routeEntity].url, $scope.model).then(success, error);
			} else {
				repository.createSingle(repository.apiData[routeEntity].url, $scope.model).then(success, error);
			}
		};

		$scope.delete = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-entity-message', "Objektet vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-entity-button-confirm', 'Ja, fjern objektet!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				repository.deleteSingle(repository.apiData[routeEntity].url, $scope.model.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-delete-success', 'Sletting fullført!'), result, "success");
						$scope.goBack(routeEntity + '.list', { menuGuid: $scope.navigation.params.menuGuid });
					});
			});
		};

		$scope.goBack = function () {
			$rootScope.goBack(routeEntity + '.list', { menuGuid: $scope.navigation.params.menuGuid });
		};

		$scope.$on($scope.events.newSelection, function () {
			updateAccess();
		});
	}
})();
